import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Box,
    Text,
    Heading,
    useDisclosure,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalFooter,
    RadioGroup,
    Stack,
    Select,
    Radio,
    Textarea,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import {
    Season,
    useGetGames,
    useGetUserInfoByEmail,
    useSendPersonalNotification,
} from './data'
import UserResult from './UserResult/UserResult'
import { Notification } from './UserResult/components/Notification'
import { permissions, useAuthenticatedUser } from './Authentication'
import { DeepLinkGeneration } from './components/DeepLinkGeneration'

const StyledForm = styled('form')({})

export function SearchForm() {
    const [
        getInfo,
        { isError, error, reset, data, isLoading },
    ] = useGetUserInfoByEmail()

    const cancelRef = React.useRef<HTMLButtonElement>()

    const { data: allGames } = useGetGames()
    const games = allGames?.filter((x) => x.published)
    const [email, setEmail] = React.useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenLinking,
        onOpen: onOpenLinking,
        onClose: onCloseLinking,
    } = useDisclosure()
    const [isConfirmationOpen, setConfirmationOpen] = useState(false)
    const [notificationType, setNotificationType] = useState<string>('Tipping')
    const [deliveryType, setDeliveryType] = useState<string>('Both')
    const [audience, setAudience] = useState<string>('')
    const [notificationBody, setNotificationBody] = useState<string>('')
    const [userSubs, setUserSubs] = useState<string>('')
    const [notificationTitle, setNotificationTitle] = useState<string>('')
    const [index, setIndex] = useState<number | undefined>()
    const [selectedGame, setSelectedGame] = useState<Season>()

    const user = useAuthenticatedUser()

    const validNotificationType = notificationType === 'Tipping'
    const isValid =
        (validNotificationType &&
            notificationBody &&
            notificationTitle &&
            deliveryType &&
            audience === 'All') ||
        (validNotificationType &&
            notificationBody &&
            notificationTitle &&
            deliveryType &&
            audience !== 'All' &&
            selectedGame)
    const [sendNotification] = useSendPersonalNotification()

    const onSendPressed = () => {
        if (isValid) {
            const body = JSON.stringify({
                body: notificationBody,
                title: notificationTitle,
                sport: selectedGame?.sport || '',
                type: notificationType,
                seasonName: selectedGame?.season || '',
                round: selectedGame?.currentRound.toString(),
                audience,
                userSubs: userSubs
                    .split(',')
                    .filter((sub) => sub.trim() !== ''),
                deliveryType,
            })
            sendNotification(body)
            handleClose()
        }
    }

    function handleClose() {
        setSelectedGame(undefined)
        setIndex(undefined)
        setNotificationBody('')
        setNotificationTitle('')
        setAudience('')
        setUserSubs('')
        setConfirmationOpen(false)
        onClose()
    }

    return (
        <>
            <Box>
                <Grid px="10" templateColumns="repeat(4, 1fr)" gap="10">
                    <FormControl as={GridItem} id="email">
                        <StyledForm
                            onSubmit={(e) => {
                                e.preventDefault()
                                getInfo(email)
                            }}
                        >
                            <FormLabel htmlFor="email" fontSize={'xx-large'}>
                                Search User
                            </FormLabel>
                            <Grid templateColumns="repeat(4, 1fr)" gap="2">
                                <GridItem colSpan={3}>
                                    <Input
                                        name="email"
                                        type="text"
                                        placeholder="Enter user's email"
                                        value={email}
                                        onChange={(event) =>
                                            setEmail(event.target.value)
                                        }
                                    />
                                </GridItem>
                                <GridItem
                                    as={Button}
                                    onClick={() => {
                                        getInfo(email)
                                    }}
                                    isLoading={isLoading}
                                    disabled={!email}
                                    colSpan={1}
                                >
                                    Search
                                </GridItem>
                            </Grid>
                        </StyledForm>
                    </FormControl>
                    {user &&
                        (user.hasPermission(permissions.admin) ||
                            user.hasPermission(permissions.commercial)) && (
                            <Grid templateColumns="repeat(3, 1fr)" gap="2">
                                <GridItem colSpan={3} pt="2">
                                    <Heading
                                        fontSize="xx-large"
                                        fontWeight="normal"
                                    >
                                        Push Notifications
                                    </Heading>
                                </GridItem>
                                <GridItem
                                    as={Button}
                                    colSpan={2}
                                    onClick={onOpen}
                                >
                                    Send Notification
                                </GridItem>
                            </Grid>
                        )}
                    <Grid templateColumns="repeat(3, 1fr)" gap="2">
                        <GridItem colSpan={3} pt="2">
                            <Heading fontSize="xx-large" fontWeight="normal">
                                Mobile Link
                            </Heading>
                        </GridItem>
                        <GridItem
                            as={Button}
                            colSpan={2}
                            onClick={onOpenLinking}
                        >
                            Build Deep link
                        </GridItem>
                    </Grid>
                    <GridItem
                        as={Divider}
                        mt={6}
                        mb={10}
                        borderColor="black"
                        colSpan={4}
                    />
                    {data && !isError && (
                        <GridItem colSpan={4}>
                            <UserResult key={data.subject} data={data} />
                        </GridItem>
                    )}
                    {isError && (
                        <Notification
                            type="error"
                            message={error as string}
                            hideTimeout={5000}
                            onNotificationClose={reset}
                        />
                    )}
                </Grid>
                {!data && !isLoading && (
                    <Box px="10" mt={10}>
                        <Text
                            fontSize="md"
                            as="i"
                            display="flex"
                            justifyContent="center"
                        >
                            Search for a user above to inspect their details.
                        </Text>
                        <Box as={Divider} mt={20} borderColor="black" />
                    </Box>
                )}
            </Box>
            <Modal size="4xl" isCentered isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton onClick={handleClose} />
                    <ModalBody>
                        <Box>
                            <ModalHeader padding="0">
                                <Box>
                                    <Heading>Create Push Notification</Heading>
                                    <Text fontWeight="normal" fontSize="medium">
                                        This tool will send a notification to
                                        the users, based on the input you give
                                        it.
                                    </Text>
                                </Box>
                            </ModalHeader>
                            <Divider margin="16px 0px" />
                            <Heading fontSize="Small" fontWeight="normal">
                                Who are we messaging
                            </Heading>
                            <RadioGroup value={audience} onChange={setAudience}>
                                <Stack direction="row">
                                    <Radio size="md" value="All">
                                        All Members
                                    </Radio>
                                    <Radio size="md" value="Registered">
                                        Registered Members
                                    </Radio>
                                    <Radio size="md" value="Targeted">
                                        Targeted
                                    </Radio>
                                    <Radio size="md" value="NotRegistered">
                                        Not Registered
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                            <Divider margin="16px 0px" />
                            <Heading fontSize="Small" fontWeight="normal">
                                Category
                            </Heading>
                            <RadioGroup
                                value={notificationType}
                                onChange={setNotificationType}
                            >
                                <Stack direction="row">
                                    <Radio value="Tipping">Tipping</Radio>
                                </Stack>
                            </RadioGroup>
                            <Divider margin="16px 0px" />
                            <Heading fontSize="Small" fontWeight="normal">
                                Type of delivery
                            </Heading>
                            <RadioGroup
                                value={deliveryType}
                                onChange={setDeliveryType}
                            >
                                <Stack direction="row">
                                    <Radio value="Both">
                                        Send both push and personal notification
                                    </Radio>
                                    <Radio value="Personal">
                                        Send personal notification only
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                            <Divider margin="16px 0px" />
                            {games && audience !== 'All' && (
                                <Box>
                                    <Select
                                        placeholder="Select sport/season..."
                                        value={index}
                                        onChange={(e) => {
                                            setIndex(parseInt(e.target.value))
                                            setSelectedGame(
                                                games[e.target.value],
                                            )
                                        }}
                                    >
                                        {games.map((game, idx) => (
                                            <option key={idx} value={idx}>
                                                {game.sport + ' ' + game.season}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                            )}
                            <Divider margin="16px 0px" />
                            <Box>
                                <Input
                                    name="body"
                                    type="text"
                                    placeholder="Enter notification Title"
                                    value={notificationTitle}
                                    size="md"
                                    onChange={(event) =>
                                        setNotificationTitle(event.target.value)
                                    }
                                />
                                <Textarea
                                    margin="16px 0px"
                                    name="body"
                                    type="text"
                                    placeholder="Enter notification Body"
                                    value={notificationBody}
                                    size="md"
                                    onChange={(event) =>
                                        setNotificationBody(event.target.value)
                                    }
                                />
                                {audience === 'Targeted' && (
                                    <Textarea
                                        margin="16px 0px"
                                        name="body"
                                        type="text"
                                        placeholder="Enter User Subs (Separated by Commas)"
                                        value={userSubs}
                                        size="md"
                                        onChange={(event) =>
                                            setUserSubs(event.target.value)
                                        }
                                    />
                                )}
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter display="flex" justifyContent="center">
                        <Box display="flex">
                            <GridItem
                                as={Button}
                                ml={2}
                                colorScheme="gray"
                                onClick={() => {
                                    setConfirmationOpen(true)
                                }}
                                disabled={!isValid}
                            >
                                Send
                            </GridItem>
                            <Button
                                variant="ghost"
                                onClick={() => handleClose()}
                            >
                                <span>Cancel</span>
                            </Button>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <DeepLinkGeneration
                games={games}
                onCloseLinking={onCloseLinking}
                isOpenLinking={isOpenLinking}
            />
            <AlertDialog
                isOpen={isConfirmationOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Send Push Notification
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to send to {audience} for{' '}
                            {selectedGame?.sport}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={() => {
                                    setConfirmationOpen(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                colorScheme="green"
                                onClick={onSendPressed}
                                ml={3}
                            >
                                Send
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
